import frLocal from 'element-ui/lib/locale/lang/fr'
import ac_fr from "./activity/ac_fr";
const fr = {
    400000: 'Académie',
    400001: 'Actualités',
    400002: 'Données',
    400003: 'Vidéos',
    400004: 'Apprendre',
    400005: 'Connexion',
    400006: "S'enregistrer",
    400007: 'Portefeuille',
    400008: "Vue d'ensemble du portefeuille",
    400009: 'Compte Main Market',

    400010: 'Compte P2P',
    400011: 'Futures',
    400012: 'Historique du portefeuille',
    400013: 'Ordre Main Market',
    400014: 'Ordres Futures',
    400015: "Vue d'ensemble",
    400016: "Vérification d'identité",
    400017: 'Sécurité du compte',
    400018: 'Remise',
    400019: 'Préférences',

    400020: 'Centre de coupons',
    400021: 'Déconnexion',
    400022: '<em class="normal">{num}</em> notifications en attente',
    400023: 'Tout effacer',
    400024: 'Afficher plus',
    400025: 'Aucun enregistrement trouvé',
    400026: "Scanner pour télécharger l'application",
    400027: "Plus d'options de téléchargement",
    400028: 'Détails de la notification',
    400029: "D'accord",

    400030: 'P2P',
    400031: 'Marchés',
    400032: 'Spot',
    400033: 'Futures',
    400034: 'Super Start',
    400035: "Cette adresse n'existe pas",
    400036: "La plateforme d'échange d'actifs numériques la plus fiable au monde",
    400037: ' A propos de nous',
    400038: 'Conditions',
    400039: 'Confidentialité',

    400040: 'Annonces',
    400041: 'Apprendre',
    400042: 'Centre pour débutants',
    400043: 'Trading spot',
    400044: 'Futures',
    400045: 'FAQ',
    400046: 'Service',
    400047: 'Frais',
    400048: 'Règles',
    400049: 'Demande de cotation',

    400050: 'Remise',
    400051: 'Contactez-nous',
    400052: "Centre d'aide",
    400053: 'Soumettre une demande',
    400054: 'Contacts professionnels',
    400055: 'Coopération institutionnelle',
    400056: 'Langue et région',
    400057: 'Devise',
    400058: 'Choisir la langue et région',
    400059: 'Choisir le taux de change',

    400060: 'Transfert',
    400061: 'Les transferts internes de SuperEx ne facturent pas de frais',
    400062: 'De',
    400063: 'à',
    400064: 'Paire',
    400065: 'Déduction des frais',
    400066: 'Croisé',
    400067: 'Isolé',
    400068: 'Fractionné',
    400069: 'solde',

    400070: 'Cryptomonnaie',
    400071: 'Rechercher',
    400072: 'Montant',
    400073: 'Disponible',
    400074: 'Tout',
    400075: 'Confirmer le transfert',
    400076: 'Ouvert',
    400077: 'Essayer plus tard',
    400078: 'Ouvrir un compte Futures',
    400079: "Vous n'avez pas encore ouvert de compte à terme, les transferts ne sont donc temporairement pas disponibles.",

    400080: 'Renvoyer dans {timeout}s',
    400081: 'Envoyer le Code',
    400082: 'Toutes les cryptomonnaies.',
    400083: 'Toutes les devises fiat',
    400084: 'Tous les statuts.',
    400085: 'Zone optionnelle',
    400086: 'Tierce partie',
    400087: 'Guide',
    400088: 'Commande',
    400089: 'Centre des marchands P2P',

    400090: 'Centre utilisateur P2P',
    400091: 'Méthode de paiement',
    400092: 'Publier une nouvelle annonce',
    400093: 'Mes annonces',
    400094: 'Plus',
    400095: 'Postuler',
    400096: 'Annuler',
    400097: "Vous ne pouvez pas encore publier d'annonces",
    400098: "Veuillez d'abord demander à devenir un annonceur",
    400099: 'Obtenir une vérification',

    400100: 'Non vérifié',
    400101: "Vous n'avez pas terminé la vérification d'identité",
    400102: "Pour la sécurité de votre compte avec d'autres utilisateurs de trading, veuillez d'abord terminer la vérification d'identité",
    400103: 'Définir un pseudonyme',
    400104: 'Définissez le surnom de votre compte. Il est recommandé de ne pas utiliser votre vrai nom. La longueur du surnom est de 2 à 10 caractères.',
    400105: 'Désolé, vous ne pouvez pas postuler pour devenir un commerçant en ce moment. ',
    400106: 'Configuration réussie',
    400107: 'Avantages du P2P de SuperEx',
    400108: '0 frais',
    400109: "Échangez facilement et rapidement du Bitcoin et d'autres cryptomonnaies sans frais de commission du côté de la commande sur SuperEx P2P !",

    400110: 'Méthodes de paiement flexibles',
    400111: 'SuperEx P2P permet au vendeur de choisir librement le mode de paiement.',
    400112: 'Tradez au meilleur prix',
    400113: 'SuperEx P2P permet aux utilisateurs de fixer leurs propres prix et de choisir leur prix préféré pour acheter et vendre des cryptomonnaies.',
    400114: 'Tradez à des prix avantageux',
    400115: "SuperEx P2P adopte un mécanisme de retrait d'argent T+n, gère strictement les commerçants et filtre les transactions suspectes à l'aide de données de contrôle des risques afin de garantir la sécurité des actifs de la plateforme et permettre aux utilisateurs de trader en toute sécurité.",
    400116: '{amount} Volume',
    400117: '{num} %Taux de transaction',
    400118: 'Prix',
    400119: 'Délai de paiement',

    400120: 'Mode de paiement du vendeur',
    400121: 'Conditions de transaction',
    400122: 'Je vais payer.',
    400123: 'Je vais recevoir',
    400124: 'Je veux vendre',
    400125: 'Méthode de paiement',
    400126: 'Configurer le mode de paiement',
    400127: 'Ajouter un moyen de paiement',
    400128: 'Prix unitaire de référence {price}',
    400129: 'Acheter',

    400130: 'Vendre',
    400131: 'Afin de protéger la sécurité des fonds, il y aura une limite de retrait {limit} pour les actifs achetés.',
    400132: 'En savoir plus',
    400133: 'Je l’ai compris',
    400134: "Conformément aux normes opérationnelles de la plateforme et de votre pays d'identité, veuillez passer à la devise nationale pour la transaction",
    400135: "Limite d'achat: {min}-{max} {fiat}",
    400136: 'Limite de vente: {min}-{max} {fiat}',
    400137: 'Annonceur',
    400138: 'Limite/Quantité',
    400139: 'Trade',

    400140: 'Montant',
    400141: 'Limite',
    400142: 'Processus',
    400143: "D'accord",
    400144: "Veuillez effectuer une vérification d'identité avant de commencer les transactions.",
    400145: "Vos annulations de commandes aujourd'hui ont dépassé {num} fois, et vous ne pouvez pas continuer à trader. Veuillez réessayer après {time}.",
    400146: "Vous avez atteint le nombre maximum de commandes en attente ({num}), veuillez d'abord terminer la commande en cours.",
    400147: "Cet annonceur a atteint le nombre maximum de commandes en attente, veuillez effectuer l'opération ultérieurement ou essayer une autre annonce.",
    400148: "Vous ne répondez pas aux exigences de l'annonceur, veuillez essayer une autre annonce.",
    400149: 'Sélectionner',

    400150: 'Comment fonctionne le P2P',
    400151: 'Acheter des crypto-monnaies',
    400152: 'Vendre des crypto-monnaies',
    400153: 'Passer une commande',
    400154: 'Une fois la commande passée, les actifs de la transaction seront sous la garde de SuperEx P2P.',
    400155: 'Payer le vendeur',
    400156: `Envoyez de l'argent au vendeur via la méthode de paiement dans les informations de la transaction et cliquez sur "Payé, Aviser le vendeur" sur SuperEx P2P après avoir effectué le transfert.`,
    400157: 'Obtenez votre crypto-monnaie',
    400158: 'Une fois que le vendeur confirme la réception du paiement, la cryptomonnaie en garde sera libérée vers vous.',
    400159: 'Confirmer le paiement',

    400160: "Vérifiez l'historique des transactions dans votre compte et assurez-vous d'avoir reçu le paiement de l'acheteur.",
    400161: 'Envoyer la cryptomonnaie ',
    400162: 'Envoyez de la cryptomonnaie aux acheteurs sur SuperEx P2P après avoir confirmé la réception du paiement.',
    400163: 'Montant',
    400164: 'Saisir le montant',
    400165: 'Fiat',
    400166: 'Toutes les méthodes de paiement',
    400167: 'ID annonce',
    400168: 'Confirmer',
    400169: "Êtes-vous sûr de vouloir supprimer l'annonce ?",

    400170: '1. Les utilisateurs qui suppriment les annonces ne pourront pas trader avec vous via les annonces',
    400171: "2. La suppression des annonces n'affecte pas les commandes en cours ",
    400172: 'Êtes-vous sûr de vouloir supprimer cette annonce ? ',
    400173: 'Modifier',
    400174: 'Supprimer',
    400175: 'Ajouté',
    400176: 'Supprimé',
    400177: 'Créé',
    400178: 'Limite minimale',
    400179: 'Quantité restante/quantité commandée',

    400180: 'Prix de commande',
    400181: 'Type',
    400182: 'Mise en ligne réussie',
    400183: 'Tous les types',
    400184: 'Statut',
    400185: 'ID annonce/Devise/Date',
    400186: 'Opérer',
    400187: 'Selon les spécifications opérationnelles du pays ou de la région où votre identité SuperEx est certifiée et de la plateforme, veuillez choisir la devise légale prise en charge pour la transaction.',
    400188: 'Restreint',
    400189: 'Supprimé',

    400190: 'Retiré avec succès',
    400191: 'Poster des annonces',
    400192: "Modifier l'annonce",
    400193: 'Prix fixe',
    400194: 'Prix flottant',
    400195: 'Taux de fluctuation de prix',
    400196: 'Entrer',
    400197: 'Prix de transaction',
    400198: 'Prix de référence du marché',
    400199: "La définition d'un pourcentage flottant vous permet d'acheter et de vendre à un pourcentage supérieur ou inférieur au prix de référence réel du marché.",

    400200: 'Les prix fixes ne changent pas avec les fluctuations du marché',
    400201: 'Restrictions de transaction',
    400202: 'Saisissez le volume de la transaction.',
    400203: 'Nombre de transactions',
    400204: 'Limite minimale de vente',
    400205: 'Veuillez entrer le montant minimum par transaction',
    400206: 'Limite maximale de vente',
    400207: 'Veuillez entrer le montant maximal par transaction',
    400208: 'Max',
    400209: "L'annonce nécessite des frais d'environ {rate}% {currency}, actuellement disponible {available} {currency}.",

    400210: 'Moyen de paiement',
    400211: "Sélectionnez jusqu'à 3.",
    400212: 'Aucune méthode de paiement pour le moment',
    400213: 'Choisir la limite de temps de paiement',
    400214: 'Saisir le contenu',
    400215: "Conditions de l'utilisateur de transaction",
    400216: "L'augmentation de la limite d'utilisateur de transaction réduira les chances d'affichage de votre annonce.",
    400217: 'Inscription complète',
    400218: 'jours plus tard',
    400219: 'Lancer maintenant',

    400220: 'Lancer plus tard',
    400221: 'Avertissement de risque',
    400222: 'Vous devez entrer un nombre',
    400223: 'Choisir la méthode de paiement',
    400224: 'Saisissez le prix flottant.',
    400225: 'Entrez un prix fixe',
    400226: "Le prix s'est écarté du marché de {num} %, la poursuite de la publication sera risquée, veuillez confirmer si vous souhaitez continuer à publier des annonces",
    400227: 'La limite maximale par transaction doit être supérieure à la limite minimale',
    400228: 'La limite maximale de transaction unique ne peut pas être supérieure à {num} {fiat}',
    400229: 'La limite minimale de transaction unique ne peut pas être inférieure à {num} {fiat}',

    400230: 'La limite minimale de commande unique doit être inférieure à la limite maximale de commande unique',
    400231: 'Le montant maximum de la transaction ne doit pas être supérieur à {num} {currency}.',
    400232: 'Publication réussie',
    400233: "Le trading P2P n'est pas ouvert aux utilisateurs de portefeuille autorisés pour le moment, veuillez utiliser votre compte SuperEx pour vous connecter et trader en P2P",
    400234: 'Réseau DAO',
    400235: 'Roadmap',
    400236: 'Inviter des amis',
    400237: "Centre d'aide",
    400238: 'Centre de Récompenses',
    400239: 'Explorer Mainnet',
    400240: 'Explorer Testnet',
    400241: 'Documentation du développeur',
    400242: 'Centre des Événements',
    400243:'En utilisant le site web SuperEx et/ou en tenant votre compte, vous acceptez nos <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/fr/articles/22450838380569">conditions d’utilisation</a>, notre <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/fr/articles/22456031222041">politique de confidentialité</a> et notre <a style="color: inherit;text-decoration: underline;" target="_blank" href="https://support.superex.com/hc/fr/articles/22490451158297">politique en matière de cookies</a> mises à jour. En cliquant sur le "X", vous confirmez que vous avez lu et accepté les conditions et politiques ci-dessus.',
    400244: 'Achetez Tether | Obtenez USDT | SuperEx P2P',
    400245: 'Centre de tâches',
    400246: 'Devise',
    400247: 'Recevoir',
    400248: 'Dépenses',
    400249: 'Disponible:',

    400250: 'Payer',
    400251: 'Détails de la commande',
    400252: 'Montant (frais de transaction inclus)',
    400253: "Temps d'arrivée",
    400254: '{time} minutes',
    400255: 'Note',
    // 400256: '1.您将离开SUPEREX并进入第三方{name}网页以完成您的订单。',
    // 400257: '2. 订单完成后，您可以前往您的 <span class="pointer">财务记录</span> 查看详细信息。',
    // 400258: '3. 如果您需要付款方面的帮助，请联系付款提供商的 <span class="pointer">客户支持</span>。',
    // 400259: '4. 更多详情请参阅常见问题解答。 <span class="pointer">常问问题</span>',

    400260: "J'ai lu et j'accepte la clause de non-responsabilité",
    400261: 'Comment acheter des crypto-monnaies sur SuperEx ?',
    400262: 'Comment Vendre des Cryptomonnaies sur SuperEx?',
    400263: 'Passez votre commande de crypto',
    400264: 'Choisissez la devise fiduciaire avec laquelle vous souhaitez payer et la crypto-monnaie que vous souhaitez acheter. Indiquez le montant que vous souhaitez dépenser.',
    400265: 'Vérifiez votre identité',
    400266: 'Effectuez la vérification KYC sur SuperEx et le fournisseur de paiement pour finaliser le paiement.',
    400267: 'Vérifiez les détails de votre commande',
    400268: "Consultez votre historique de dépôts pour voir l'état de votre commande.",
    400269: '1. Quels modes de paiement puis-je utiliser pour acheter des cryptomonnaies ?',

    400270: "SuperEx prend actuellement en charge les modes de paiement VISA, Mastercard, Apple Pay, Google Pay et d'autres. Les prestataires de services tiers pris en charge incluent Mercuryo et alchemypay.",
    400271: '2. Quelles cryptomonnaies puis-je acheter ?',
    400272: 'SuperEx prend en charge les cryptomonnaies populaires telles que BTC, ETH, USDT, LTC, USDC, XRP, BCH, CRV et TRX.',
    400273: '3. SuperEx surveille-t-il la qualité et les prix des prestataires de services tiers pris en charge ?',
    400274: "SuperEx a soigneusement sélectionné un certain nombre de prestataires de services ayant une réputation fiable dans l'industrie, une popularité et une sécurité pour fournir aux utilisateurs des services simplifiés.",
    400275: '4. Quels frais dois-je payer pour acheter des cryptomonnaies ?',
    400276: 'Vous payez des frais au prestataire de services tiers et des frais de transfert sur la chaîne. SuperEx ne facture aucun frais.',
    400277: '5. Combien de temps faut-il pour recevoir les cryptomonnaies après le paiement ?',
    400278: 'Après que votre paiement est effectué sur la plateforme du prestataire de services tiers, vos cryptomonnaies seront déposées sur votre compte spot sur SuperEx en environ 2 à 10 minutes.',
    400279: '6. Que faire si je rencontre des problèmes lors du processus d\'achat ?',

    400280: "Contactez le support client si vous rencontrez des problèmes lors du processus de transaction. Si vous n'avez pas reçu les cryptomonnaies après l'achèvement du paiement, contactez le prestataire de services tiers pour vérifier les détails de la commande (c'est généralement la méthode la plus efficace). En raison de l'adresse IP de votre région actuelle ou de certaines raisons politiques, vous devrez peut-être sélectionner une vérification humaine.",
    400281: 'Limite de transaction unique :',
    // 400282: '请输入数量',
    400283: 'Fournisseur de paiement',
    400284: 'Obtenir',
    400285: 'Solde insuffisant',
    400286: "You will leave SuperEx and jump to <span>{name}</span>, a licensed financial institution that provides card payment processing capabilities as a third party. Any risk events that occur during use will be borne by <span>{name}</span>. Please read and agree to <a class= 'pointer' target='_blank' href={server}>Service Agreement</a> before using its services.If you have any questions about credit card deposits, please contact <a class='pointer' target='_blank' href={Customer}>{name} Customer Service</a> for more details.",
    400287: 'Avertissement',
    400289: 'Compte Spot disponible',
    400290: 'Confirmer',
    400291: 'Zone ET',


    400293: 'https://support.superex.com/hc/en-001/articles/23084120565017',
    400294: 'Acheter des cryptos',

    400295:'Veuillez ouvrir le navigateur Safari pour utiliser Apple Pay.',
    400288: 'Finance',
    400292:'Compte financier',

    410000:'SCS Largage aérien',
    410001:'Agents',

    410002:'Finance',
    100756: 'Gagner',
    100219: 'Activité',
    101154: 'Historique de Souscription',


    122000:"Réseau DAO mondial et liens vers les réseaux sociaux",
    122001:"Invitez des amis pour obtenir des récompenses",
    122002:"Plan de développement de SuperEx",
    122003:"Solutions aux problèmes courants rencontrés lors de l'utilisation",
    122004:"Accomplissez des tâches pour obtenir des récompenses",
    122005:"Introduction à ET",
    122006:"Présentation de SuperEx",
    122007:"Remise pour la recommandation d'agent",
    122008:"Frais réduits sur l'achat de cryptomonnaie",
    122009:"Achats rapides de cryptomonnaie",
    122010:"Marché de trading de cryptomonnaie",
    122011:"Échange de tokens sur Free Market et Main Market",
    122012:"Marché de trading à terme",
    122013:"Plateforme IEO pour des projets de grande valeur",
    122014:"Économiser et gérer de l'argent pour gagner des revenus",
    122015:"Shopping à prix ultra bas",
    122016:"Hub pour des activités passionnantes",
    122017:"Prenez de l'avance dans l'écosystème BTC avec de nouvelles cryptomonnaies",
    122018:"Centre des tâches SCS Airdrop",
    122019:"Site Web officiel de SCS Chain",
    122020:"Navigateur SCS Chain",
    122021:"Navigateur Testnet SCS Chain",
    122022:"Documentation pour les développeurs",
    122023:"Parcourir les dernières nouvelles",
    122024:"Obtenir un aperçu rapide des derniers événements",
    122025:"Vérifier SuperEx sur Youtube",
    122026:"Tout apprendre sur la blockchain",
    122027:"Ouvrez une boîte surprise avec vos amis",
    122028: 'Boite de Surprises',

    //合约积分
    9100007: 'Points de Trading',
    9100031: `Centre d'Activités des Points de Trading`,

    9100032: 'Fonds quantitatifs',
    9100033: 'Gestion professionnelle des actifs',

    8100000: 'Retour d\'utilisateur',
    8000153: 'Lister des Jetons',
    8000154: 'Marchés de protocole BTC',
    6000072: 'Ordre Free Market',
    6000073: 'Compte Free Market',

    9100162: 'Gestion des API',

    'target_1': 'Spot',
    'target_2': 'Compte P2P',
    'target_3': '币本位合約賬戶',
    'target_4': 'USDⓢ-M',
    'target_5': '超级合約賬戶',

    ...frLocal,
    ...ac_fr
}
export default fr;
